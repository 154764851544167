require("@rails/ujs").start();
require("turbolinks").start();

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

// Stylesheets
import "../stylesheets/normalize.css";
import "../fonts/icons/icons.css";
import "../stylesheets/global.css";

/*
  Scroll suave a un anchor.
*/
function scrollAnchors(e, respond = null) {
  e.preventDefault();

  const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

  let targetID, targetAnchor;

  targetID = (respond) ? respond.getAttribute("href") : this.getAttribute("href");

  if (targetID && targetID != "#") {
    targetAnchor = document.querySelector(targetID);
  }

  if (targetAnchor) {
    const originalTop = distanceToTop(targetAnchor);

    window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });

    const checkIfDone = setInterval(function() {
      const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
      if (distanceToTop(targetAnchor) === 0 || atBottom) {
        targetAnchor.tabIndex = "-1";
        targetAnchor.focus();
        window.history.pushState("", "", targetID);
        clearInterval(checkIfDone);
      }
    }, 100);
  }
}

window.throttle = function(func, wait, options) {
  let context, args, result;
  let timeout = null;
  let previous = 0;

  if (!options) {
    options = {};
  }

  let later = function() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);

    if (!timeout) {
      context = args = null; }
  };

  return function() {
    let now = Date.now();
    if (!previous && options.leading === false) previous = now;
    let remaining = wait - (now - previous);
    context = this;
    args = arguments;

    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      previous = now;
      result = func.apply(context, args);

      if (!timeout) {
        context = args = null; }

    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining); }

    return result;
  };
};

window.debounce = function(func, wait = 100) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

window.isInViewport = function(element) {
  var rect = element.getBoundingClientRect();
  var html = document.documentElement;

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || html.clientHeight) &&
    rect.right <= (window.innerWidth || html.clientWidth)
  );
}

document.addEventListener("turbolinks:load", function() {
  // bug fix: turbolinks y anchors
  document.addEventListener('turbolinks:click', function(event) {
    if (event.target.getAttribute('href').charAt(0) === '#') {
      return event.preventDefault();
    }
  });

  // bug fix: botón atrás en Firefox
  window.addEventListener("unload", event => {
    window.removeEventListener("unload", this);
  });

  // scroll suave
  const links = document.getElementsByTagName("a");

	for (let i = 0; i < links.length; i++) {
		const link = links[i];

    if (
          (link.href && link.href.indexOf("#") != -1)
          &&
          (link.pathname == location.pathname || ('/' + link.pathname == location.pathname && link.search == location.search))
        )
    {
      link.onclick = scrollAnchors;
    }
  }

  if (window.innerWidth > 1025) {
    window.addEventListener("resize", debounce(function() {
      location.reload();
    }, 100));
  }

  // devuelve el scroll al inicio al cambiar de o recargar la página
  Turbolinks.BrowserAdapter.prototype.reload = function () {
    window.scrollTo(0, 0);
    window.location.reload();
  }
});

// if (!Turbolinks) {
//   location.reload();
// }
