export default class ReadMore {
  constructor() {
    let triggers = document.querySelectorAll(".read-more--trigger");

    for (let trigger of triggers) {
      const target = document.querySelector(trigger.getAttribute("data-target"));

      this.toggle(trigger, target, true);

      let targetHeight = target.offsetHeight + "px";
      target.setAttribute("data-height", targetHeight);
      target.style.height = 0;

      this.toggle(trigger, target, false);

      trigger.addEventListener("click", event => {
        this.toggle(trigger, target, !target.classList.contains("open"));
      });

      let close = target.querySelector(".read-more--close");

      if (close) {
        close.addEventListener("click", event => {
          this.toggle(trigger, target, false);
        });
      }
    }
  }

  toggle(trigger, target, open) {
    if (open) {
      trigger.classList.add("open");
      target.classList.add("open");
      target.style.height = target.getAttribute("data-height");
    }
    else {
      trigger.classList.remove("open");
      target.classList.remove("open");
      target.style.height = "0px";
    }
  }
}
