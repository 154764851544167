import "./menu.css";

document.addEventListener("turbolinks:load", function() {
  const menu = document.querySelector("#menu");

  if (menu) {
    const menuToggle = document.querySelector("#menuToggle");

    menuToggle.addEventListener("click", function() {
      menu.classList.toggle("open");
    });
  }
});
