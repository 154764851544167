import "./booking_form.css";

document.addEventListener("DOMContentLoaded", function() {
  const bookingForm = document.querySelector("#bookingForm");

  if (bookingForm) {
    const SUCCESS_MSG = {"es": "¡Enviado!", "en": "Sent!", "ca": "Enviat!", "fr": "Envoyé!"};

    bookingForm.addEventListener("ajax:beforeSend", function(event) {
      event.target.classList.add("sending");
    });

    bookingForm.addEventListener("ajax:success", function(event) {
      const form = event.target;

      form.classList.remove("sending", "error");

      const submitButton = form.querySelector(".btn.submit");
      submitButton.style.backgroundColor = "#FF4E00";
      submitButton.style.borderColor = "#FF4E00";
      submitButton.style.pointerEvents = "none";
      submitButton.querySelector("span").innerText = SUCCESS_MSG[document.documentElement.lang];

      form.reset();
    });

    bookingForm.addEventListener("ajax:error", function(event) {
      const form = event.target;

      form.querySelector(".error").classList.add("visible");

      form.classList.remove("sending");
      form.classList.add("error");
    });
  }
});
