import "./footer.css";

document.addEventListener("turbolinks:load", function() {
  let footer = document.querySelector("#siteFooter");

  if (footer) {
    let height = footer.offsetHeight || footer.clientHeight;
    document.querySelector(".page").style.marginBottom = `${height}px`;
  }
});
