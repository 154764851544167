import "./cookies_modal.css";

window.createCookie = function(name, value, days) {
  let expires = "";

  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }

  document.cookie = `${name}=${value}${expires}; path=/`;
};

window.readCookie = function(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];

    while (c.charAt(0) == " ") {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return undefined;
};

window.eraseCookie = function(name) {
  createCookie(name, "", -1);
};

document.addEventListener("turbolinks:load", function() {
  const cookie = "display_uji_cookies_accepted";
  const cookiesModal = document.querySelector("#cookiesModal");

  if (cookiesModal != undefined) {
    if (readCookie(cookie) == undefined) {
      cookiesModal.classList.add("present");

      cookiesModal.querySelector(".ok").addEventListener("click", function() {
        createCookie(cookie, "displayed", 365);
        cookiesModal.classList.add("hide");
      }, false);
    } else {
      cookiesModal.remove();
    }
  }
});
