import "./contact_form.css";

document.addEventListener("DOMContentLoaded", function() {
  const contactForm = document.querySelector("#contactForm");

  if (contactForm) {
    const SUCCESS_MSG = {"es": "¡Enviado!", "en": "Sent!", "ca": "Enviat!", "fr": "Envoyé!"};

    contactForm.addEventListener("ajax:beforeSend", function(event) {
      event.target.classList.add("sending");
    });

    contactForm.addEventListener("ajax:success", function(event) {
      const form = event.target;

      form.classList.remove("sending", "error");

      const submitButton = form.querySelector(".btn.submit");
      submitButton.style.backgroundColor = "#FF4E00";
      submitButton.style.borderColor = "#FF4E00";
      submitButton.style.pointerEvents = "none";
      submitButton.querySelector("span").innerText = SUCCESS_MSG[document.documentElement.lang];

      form.reset();
    });

    contactForm.addEventListener("ajax:error", function(event) {
      const form = event.target;

      form.querySelector(".error").classList.add("visible");

      form.classList.remove("sending");
      form.classList.add("error");
    });
  }
});
